<script>
import ImageBackgroundVue from '@/components/ui/ImageBackground.vue'
import { getContentById } from '@/api/admin/contents'
import TextViewerVue from '@/components/TextViewer.vue'

import MainBackground from '@/assets/images/004.jpg'

export default {
  components: { TextViewerVue, ImageBackgroundVue },
  data() {
    return {
      src: MainBackground,
      board: null,
      boardData: {
        boardName: 'content',
        boardId: 0
      }
    }
  },
  setup() {},
  created() {},
  async mounted() {
    this.board = await this.loadData(this.$route.params.id)
  },
  watch: {
    async $route() {
      this.board = await this.loadData(this.$route.params.id)
    }
  },
  unmounted() {},
  methods: {
    async loadData(id) {
      this.boardData.boardId = id
      return await getContentById(this.boardData.boardId)
    }
  }
}
</script>

<template>
  <div class="wf__about-view" v-if="board">
    <div class="wf__full-width">
      <ImageBackgroundVue :src="src">
        <div>
          <h1 class="wf__default-title">{{ board.title }}</h1>
        </div>
      </ImageBackgroundVue>
    </div>

    <div class="wf__about-view-body">
      <TextViewerVue :propsData="board.content" :boardData="boardData"></TextViewerVue>
    </div>
  </div>
</template>

<style scoped>
.wf__about-view-body {
  margin-top: 3rem;
}
</style>
